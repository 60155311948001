<template>
    
</template>

<script>
export default {
    name: '',
    data(){
        return{

        }
    },

    mounted(){

    },
    methods:{
        
    }
}
</script>

<style scoped>

</style>